interface Props {
  className?: string,
  width?: number | string,
  height?: number | string
}

export function LoaderSvg({className = "", width = 24, height = 24}: Props) {
  return <svg className={className + " svg-loading"} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke="#fff"
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M12 6l0 -3"/>
    <path d="M16.25 7.75l2.15 -2.15"/>
    <path d="M18 12l3 0"/>
    <path d="M16.25 16.25l2.15 2.15"/>
    <path d="M12 18l0 3"/>
    <path d="M7.75 16.25l-2.15 2.15"/>
    <path d="M6 12l-3 0"/>
    <path d="M7.75 7.75l-2.15 -2.15"/>
  </svg>
}